import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import bruna from '../../../assets/images/artists/bruna.webp'
import titas from '../../../assets/images/artists/titas.webp'
import zimbra from '../../../assets/images/artists/zimbra.webp'
import ArtistCard from '../ArtistCard/ArtistCard'
import './ArtistsHome.scss'

const ArtistsHome = () => {
  const navigate = useNavigate();
  const artists = [
    {
      name: 'Titãs',
      backgroundImage: titas,
      socialLinks: {
        facebook: 'https://www.facebook.com/titasoficial',
        twitter: 'https://twitter.com/titasoficial',
        instagram: 'https://www.instagram.com/titasoficial/?hl=en',
        tiktok: 'https://www.tiktok.com/@titasoficial?_t=8nYcZi9CMcA&_r=1'
      },
    },
    {
      name: 'Zimbra',
      backgroundImage: zimbra,
      socialLinks: {
        facebook: 'https://www.facebook.com/bandazimbra',
        twitter: 'https://x.com/zimbraoficial',
        tiktok: 'https://www.tiktok.com/@bandazimbra?_t=8nYcijtL27Z&_r=1',
        instagram: 'https://www.instagram.com/bandazimbra/',
      },
    },
    {
      name: 'Bruna Magalhães',
      backgroundImage: bruna,
      socialLinks: {
        facebook: 'https://www.facebook.com/bruna.magalhaes.3954?locale=pt_BR',
        instagram: 'https://www.instagram.com/brunamagalhaesmusica/',
        tiktok: 'https://www.tiktok.com/@brunamagalhaes.musica?_t=8nYctlzVEKz&_r=1',
      },
    },
  ]
  return (
    <div className='ArtistsHome'>
      <div className="container-title">
        <div className="widget">
          <h1>Conheça nossos artistas</h1>
          <span>ARTISTAS</span>
        </div>
        <div className="container-artists">
          <div className="box-artists">
            {artists.map((artist) => (
              <ArtistCard name={artist.name} backgroundImage={artist.backgroundImage} socialLinks={artist.socialLinks} />
            ))}
          </div>
          <Link to="/artistas">
            <button className="btn-artists">
              Ver todos os artistas
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ArtistsHome
