import React from 'react';
import './ArtistCard.scss';

const ArtistCard = ({ name, backgroundImage, socialLinks }) => {
  return (
    <div className="Artist" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="infos">
        <p className="title">{name}</p>
        <div className="icons">
          <p> </p>
          <div>
            {socialLinks.facebook && (
              <button
                onClick={() => window.open(socialLinks.facebook, '_blank')}
                aria-label="Open Facebook profile"
                title="Open Facebook profile"
                className="social-button facebook-button"
              >
                <span className="ri--facebook-fill" aria-hidden="true" />
                <span className="sr-only">Open Facebook profile</span>
              </button>
            )}
            {socialLinks.twitter && (
              <button
                onClick={() => window.open(socialLinks.twitter, '_blank')}
                aria-label="Open Twitter profile"
                title="Open Twitter profile"
                className="social-button twitter-button"
              >
                <span className="twitter" aria-hidden="true" />
                <span className="sr-only">Open Twitter profile</span>
              </button>
            )}
            {socialLinks.instagram && (
              <button
                onClick={() => window.open(socialLinks.instagram, '_blank')}
                aria-label="Open Instagram profile"
                title="Open Instagram profile"
                className="social-button instagram-button"
              >
                <span className="mdi--instagram" aria-hidden="true" />
                <span className="sr-only">Open Instagram profile</span>
              </button>
            )}
            {socialLinks.tiktok && (
              <button
                onClick={() => window.open(socialLinks.tiktok, '_blank')}
                aria-label="Open tiktok profile"
                title="Open tiktok profile"
                className="social-button instagram-button"
              >
                <span className="mdi--tiktok" aria-hidden="true" />
                <span className="sr-only">Open Instagram profile</span>
              </button>
            )}
            {/* Adicione mais botões de redes sociais conforme necessário */}
          </div>
        </div>

      </div>
    </div>
  );
};

export default ArtistCard;
