import React from 'react'
import bg from '../../../assets/images/studio/bg.webp'
import './Studios.scss';
import ImageSwiper from '../../common/ImageSwiper/ImageSwiper';

// ESTÚDIO 1
import studioOneImage1 from '../../../assets/images/studio/1/ONE-IMAGE-1.jpg'
import studioOneImage2 from '../../../assets/images/studio/1/ONE-IMAGE-2.jpg'
import studioOneImage3 from '../../../assets/images/studio/1/ONE-IMAGE-3.jpg'
import studioOneImage4 from '../../../assets/images/studio/1/ONE-IMAGE-4.jpg'
import studioOneImage5 from '../../../assets/images/studio/1/ONE-IMAGE-5.jpg'

// ESTÚDIO 2
import studioTwoImage1 from '../../../assets/images/studio/2/TWO-IMAGE-1.jpg'
import studioTwoImage2 from '../../../assets/images/studio/2/TWO-IMAGE-2.jpg'
import studioTwoImage3 from '../../../assets/images/studio/2/TWO-IMAGE-3.jpg'
import studioTwoImage4 from '../../../assets/images/studio/2/TWO-IMAGE-4.jpg'
import studioTwoImage5 from '../../../assets/images/studio/2/TWO-IMAGE-5.jpg'
import DetailsSummary from '../../common/DetailsSummary/DetailsSummary';

//ESTÚDIO 3
import studioThreeImage1 from '../../../assets/images/studio/3/THREE-IMAGE-1.jpg'
import studioThreeImage2 from '../../../assets/images/studio/3/THREE-IMAGE-2.jpg'
import studioThreeImage3 from '../../../assets/images/studio/3/THREE-IMAGE-3.jpg'
import studioThreeImage4 from '../../../assets/images/studio/3/THREE-IMAGE-4.jpg'
import studioThreeImage5 from '../../../assets/images/studio/3/THREE-IMAGE-5.jpg'

const Studios = () => {
  const studioOne = [studioOneImage1, studioOneImage2, studioOneImage3, studioOneImage4, studioOneImage5]
  const studioTwo = [studioTwoImage1, studioTwoImage2, studioTwoImage3, studioTwoImage4, studioTwoImage5]
  const studioThree = [studioThreeImage1, studioThreeImage2, studioThreeImage3, studioThreeImage4, studioThreeImage5]

  return (
    <div className='Studios'>
      <div className="background-studios" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container-title">
          <h1>MIDAS ESTÚDIOS</h1>
          <span>ESTÚDIOS</span>
        </div>
      </div>
      <div className="all-studios">
        <div className="container-studios">
          <div className='studios-infos'>
            <div className="studio-info">
              <h1>Estúdio 1</h1>
              <ImageSwiper images={studioOne} />
              <DetailsSummary title="EQUIPAMENTOS ANALÓGICOS">
                <div className="card-body">
                  <p className="p1">
                    <strong>MICROFONES<br /></strong>
                    1 - AKG D112<br />
                    2 - AKG C414B <br />
                    2 - NEUMANN U-87<br />
                    1 - NEUMANN TLM 103<br />
                    1 - NEUMANN M-147<br />
                    1 - TELEFUNKEN AR 51<br />
                    3 - AUDIO TECHNICA AT-8543<br />
                    2 - AUDIO TECHNICA AT 4041<br />
                    2 - AUDIX D1 <br />
                    6 - AUDIX D2<br />
                    1 - AUDIX D3<br />
                    2 - SEINHEISER MD-504<br />
                    4 - SEINHEISER MD-421<br />
                    1 - RODE 1 SHURE SM-91<br />
                    1 - SHURE SM-81<br />
                    4 - SHURE SM-57<br />
                    1 - SHURE SM-58<br />
                    1 - SHURE KSM-32<br />
                    1 - CAD 1 ELECTROVOICE RE-20<br />
                  </p>
                  <p className="p1">
                    <strong>CABOS</strong><br />
                    40 - XLR<br />
                    31 - P10<br />
                    2 - MIDI<br />
                    3 - Speaker<br />
                    P10 stereo - dezenas<br />
                    4 - XLR fêmea-fêmea<br />
                  </p>
                  <p className="p1">
                    <strong>FONES</strong><br />
                    11 - Sony MDR-7506<br />
                    2 - AKG K240<br />
                    2 - Sony MDR-7502
                  </p>
                  <p className="p1">
                    <strong>ACESSÓRIOS</strong><br />
                    1 - Tension Watch<br />
                    4 - Direct Box Radial J48<br />
                    4 - Réguas<br />
                    1 - afinador<br />
                    6 - Estantes de partitura<br />
                    11 Cabos de força<br />
                    2 - Caixa Fone<br />
                    2 - Extensões de energia grandes
                  </p>
                  <p>
                    <strong>Console SSL Duality Delta 38 canais</strong>
                  </p>
                  <p>
                    <strong>Computador</strong><br />
                    Apple Mac Studio
                  </p>
                  <p>
                    <strong>DAWS</strong><br />
                    Pro Tools 2022<br />
                    Logic Pro X 10.7.7
                  </p>
                  <p>
                    <strong>Periféricos de Efeitos</strong><br />
                    1 - Lexicon 480 Multiple Processor FX<br />
                    1 - Lexicon MPX 1 Multiple Processor FX
                  </p>
                  <p>
                    <strong>Mic Preamp com EQ e Compressor</strong><br />
                    2 - Avalons 737 Vulcan Tube preamp
                  </p>
                  <p>
                    <strong>Compressores</strong><br />
                    2 - Empirical Labs EL8 -X Distressor<br />
                    5 - Empirical Labs EL7 FATSOjr<br />
                    2 - Mic preamp Ameck 9098 EQ by Rupert Neve
                  </p>
                  <p>
                    <strong>Amplifiers de Bass e Gtr</strong><br />
                    1 - Ampeg SVT 2 PRO Bass preamp<br />
                    1 - Mesa/Boggie Studio Preamp<br />
                    1 - Mesa/Boigie Triple Retifier<br />
                    1 - Marshall JCM 600<br />
                    1 - Vox Valvetronix<br />
                    1 - Accdocaudio VL15<br />
                    1 - UAD OX (Simulador de Caixas)<br />
                    1 - caixa Mesa/Boogie 4x12<br />
                    1 - caixa Laney 4x10
                  </p>
                  <p>
                    <strong>Sintetizadores</strong><br />
                    1 - Alesis DM Pro<br />
                    1 - E-MU Planet Phatt<br />
                    1 - E-MU Vintage Keys<br />
                    1 - PCP 330 Producer<br />
                    1 - E-MU Proteus/1<br />
                    1 - Yamaha TG81Z
                  </p>
                  <p>
                    <strong>Teclados</strong><br />
                    1 - Yamaha Motif XF6<br />
                    1 - Korg Kronos 88<br />
                    1 - Piano meia cauda Yamaha G3
                  </p>
                  <p>
                    <strong>Monitores de áudio</strong><br />
                    2 - Yamaha NS-10<br />
                    2 - Genelec 1031A<br />
                    2 - Quested Studio Monitor HQ 410<br />
                    1 - Genelec 7070A Active Subwoofer
                  </p>
                  <p>
                    <strong>Interfaces de áudio</strong><br />
                    1 - Digidesign Sync HD<br />
                    3 - Avid HD I/O
                  </p>
                  <p>
                    <strong>PA</strong><br />
                    Sistema amplificado da ElectroVoice EKX-15SP com subwoofer
                  </p>
                </div>
              </DetailsSummary>
              <DetailsSummary title="PLUGINS">
                <div className="card-body">
                  <p className="p1">
                    <strong>Plugins</strong><br />
                    Native komplete 13<br />
                    SSL Bundle<br />
                    Arturia V8<br />
                    Plugins Alliance Master Bundle<br />
                    Faber Filter Master Bundle<br />
                    TC System 6000<br />
                    Waves<br />
                    Serum<br />
                    Sylenth<br />
                    SubLab<br />
                    E muito mais.
                  </p>
                </div>
              </DetailsSummary>
            </div>

            {/* ESTUDIO 2 */}
            <div className="studio-info">
              <h1>Estúdio 2</h1>
              <ImageSwiper images={studioTwo} />
              <DetailsSummary title="EQUIPAMENTOS ANALÓGICOS">
                <div className="card-body">
                  <p className="p1">
                    <strong>MICROFONES</strong><br />
                    3 - Sennheiser MD421II<br />
                    3 - Shure SM57<br />
                    2 - Shure SM58<br />
                    1 - Shure SM86<br />
                    1 - AKG D112<br />
                    2 - AKG C414B<br />
                    2 - Audio-Technica AT 4041<br />
                    1 - Audio-Technica AT 4050/CM5<br />
                    1 - Audio-Technica AT 4033<br />
                    3 - Eletrovoice N/D408B<br />
                    1 - Neumann U87A<br />
                    1 - Telefunken AR51
                  </p>
                  <p className="p1">
                    <strong>CABOS</strong><br />
                    26 - XLR<br />
                    10 - P10<br />
                    3 - MIDI<br />
                    1 - Speaker<br />
                    Dezenas - P10 stereo<br />
                    2 - XLR fêmea-fêmea
                  </p>
                  <p className="p1">
                    <strong>FONES</strong><br />
                    6 - Sony MDR-7506<br />
                    4 - AKG K240
                  </p>
                  <p className="p1">
                    <strong>ACESSÓRIOS</strong><br />
                    2 - Direct Box IMP 2<br />
                    2 - Direct Box Radial J48<br />
                    1 - Tension Watch<br />
                    2 - Réguas<br />
                    1 - Pop Filter<br />
                    2 - Estantes de partitura<br />
                    1 - Cabos de força<br />
                    2 - Caixa Fone<br />
                    1 - afinador<br />
                    2 - Direct Box Countryman<br />
                    1 - Radial RE-AMPER<br />
                    2 - Extensão de energia
                  </p>
                  <p>
                    <strong>Console SSL Duality Delta 48 canais</strong>
                  </p>
                  <p>
                    <strong>Computador</strong><br />
                    Apple Mac Studio
                  </p>
                  <p>
                    <strong>DAWS</strong><br />
                    Pro Tools 2022<br />
                    Logic Pro X 10.7.7
                  </p>
                  <p>
                    <strong>Periféricos</strong><br />
                    Efeitos<br />
                    1 - Lexicon 960<br />
                    1 - TC M 5000 Digital Audio Mainframe
                  </p>
                  <p>
                    <strong>Compressores</strong><br />
                    2 - Empirical Labs EL8 Distressor<br />
                    5 - Empirical Labs EL7 FATSOjr
                  </p>
                  <p>
                    <strong>Mic Pream Valvulado</strong><br />
                    1 - TL Audio Dual Valve Equaliser
                  </p>
                  <p>
                    <strong>Amplificador de Guitarra</strong><br />
                    1 - Mesa/Boogie Studio Preamp
                  </p>
                  <p>
                    <strong>Amplificador de Baixo</strong><br />
                    1 - Gallien-Krueger 700RB
                  </p>
                  <p>
                    <strong>Controlador MIDI</strong><br />
                    1 - komplete kontrol S61
                  </p>
                  <p>
                    <strong>Bateria acústica</strong><br />
                    1 - Pearl Export Series
                  </p>
                  <p>
                    <strong>Talk box</strong><br />
                    1 - Rocktron Banshee 02
                  </p>
                  <p>
                    <strong>Monitores de áudio</strong><br />
                    2 - Yamaha NS-10<br />
                    2 - Genelec 1031A<br />
                    2 - Genelec 1038B
                  </p>
                  <p>
                    <strong>Interfaces</strong><br />
                    1 - Avid HD MAD<br />
                    2 - SSL XLogic Alpha Link Live - R<br />
                    1 - Apogee Rosetta AD Stereo 24bit A/D Converter
                  </p>
                </div>
              </DetailsSummary>
              <DetailsSummary title="PLUGINS">
                <div className="card-body">
                  <p className="p1">
                    <strong>Plugins</strong><br />
                    Native Komplete 13<br />
                    SSL Bundle<br />
                    Arturia V8<br />
                    Plugins Alliance Master Bundle<br />
                    Faber Filter Master Bundle<br />
                    TC System 6000<br />
                    Waves<br />
                    Serum<br />
                    Sylenth<br />
                    SubLab<br />
                    E muito mais.
                  </p>
                </div>
              </DetailsSummary>
            </div>


            {/* ESTUDIO 3 */}
            <div className="studio-info">
              <h1>Estúdio 3</h1>
              <ImageSwiper images={studioThree} />
              <DetailsSummary title="EQUIPAMENTOS ANALÓGICOS">
                <div className="card-body">
                  <p className="p1">
                    <strong>MICROFONES</strong><br />
                    1 - Shure SM58<br />
                    1 - Audio-Technica AT 4041<br />
                    1 - AKG C414B<br />
                    1 - Neumann U87A
                  </p>
                  <p className="p1">
                    <strong>CABOS</strong><br />
                    4 + 1 Neumann - XLR<br />
                    4 - P10<br />
                    2 - MIDI<br />
                    1 - Speaker<br />
                    1 - P10 stereo
                  </p>
                  <p className="p1">
                    <strong>FONES</strong><br />
                    2 - Sony MDR-7506
                  </p>
                  <p className="p1">
                    <strong>ACESSÓRIOS</strong><br />
                    1 - Direct Box IMP 2<br />
                    1 - Direct Box Radial J48<br />
                    1 - Caixa de fone<br />
                    1 - Estante partitura<br />
                    1 - Afinador
                  </p>
                  <p>
                    <strong>Console SSL AWS 948 Analogue Workstation System</strong>
                  </p>
                  <p>
                    <strong>Computador</strong><br />
                    Apple Mac Studio
                  </p>
                  <p>
                    <strong>DAWS</strong><br />
                    Pro Tools 2022<br />
                    Logic Pro X 10.7.7
                  </p>
                  <p>
                    <strong>Monitoração de áudio com certificação Dolby Atmos</strong><br />
                    10 - Neumann kH 80<br />
                    1 - Neumann KH 750 DSP Active Subwoofer<br />
                    2 - Genelec 1032A<br />
                    2 - Genelec 8050<br />
                    1 - Genelec 7070A Active Subwoofer
                  </p>
                  <p>
                    <strong>Periféricos</strong><br />
                    1 - BetterMaker Mastering Limiter<br />
                    1 - Manley passive Stereo EQ<br />
                    1 - SSL Fusion Stereo Analogue Colour<br />
                    2 - Empirical Labs EL8 Distressor<br />
                    2 - Empirical Labs EL7 FATSOjr<br />
                    1 - TL Audio Dual Valve Equaliser<br />
                    1 - TL Áudio Dual Valve preamp Compressor<br />
                    1 - TL Audio C-2021 Valve Compressor<br />
                    1 - Avalon Pure Class AD 2044 Compressor<br />
                    1 - Avalon Pure Class AD 2055 Equalizer<br />
                    1 - Tube-Tech Stereo Multiband Compressor SMC 2B
                  </p>
                  <p>
                    <strong>Teclado</strong><br />
                    Roland Phantom X6
                  </p>
                  <p>
                    <strong>Interface</strong><br />
                    1 - Apogee Symphony I/O MK 2<br />
                    1 - Apollo 8 UAD 2 Quad<br />
                    1 - Sonic Studio Model 304 DSP I/O Processor<br />
                    1 - Apogee Mini-Me
                  </p>
                </div>
              </DetailsSummary>
              <DetailsSummary title="PLUGINS">
                <div className="card-body">
                  <p className="p1">
                    <strong>Plugins</strong><br />
                    Native Komplete 13<br />
                    SSL Bundle<br />
                    Arturia V8<br />
                    Plugins Alliance Master Bundle<br />
                    Faber Filter Master Bundle<br />
                    TC System 6000<br />
                    Waves<br />
                    Serum<br />
                    Sylenth<br />
                    SubLab<br />
                    E muito mais.
                  </p>
                </div>
              </DetailsSummary>
            </div>

          </div>
          <div className='right-infos'>
            <div className="container-info">
              <span>Alguma dúvida?</span>
              <p>Entre em contato conosco</p>
              <ul>
                <li >
                  <span className="fa--phone" />
                  <a href='d'>+ 55 11 2256 – 5555 </a>
                </li>
                <li >
                  <span className="fa--envelope-o" />
                  <a href='mailto:contato@midas.com.br'>contato@midas.com.br</a>
                </li>
              </ul>
            </div>
            <a href="https://api.whatsapp.com/send?phone=5511994405225&amp;text=Mande%20sua%20mensagem." target="_blank" className="button-info" role="button" rel="noreferrer">
              AGENDAR
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Studios
