import React from 'react'
import bg from '../../../assets/images/partners/bg-section.webp'
import FernandoDeGino from '../../../assets/images/partners/degino.jpg'
import FernandoPrado from '../../../assets/images/partners/fernando_prado.jpg'
import Fouad from '../../../assets/images/partners/fouad.jpg'
import GabiBonadio from '../../../assets/images/partners/gabi.jpg'
import Mergulhao from '../../../assets/images/partners/leo.jpg'
import Peixoto from '../../../assets/images/partners/matheus.jpg'
import rickBonadio from '../../../assets/images/partners/rick_bonadio.jpg'
import image1 from '../../../assets/images/partners/studio/01.webp'
import image2 from '../../../assets/images/partners/studio/02.webp'
import image3 from '../../../assets/images/partners/studio/03.webp'
import image4 from '../../../assets/images/partners/studio/04.webp'
import image5 from '../../../assets/images/partners/studio/05.webp'
import image6 from '../../../assets/images/partners/studio/06.webp'
import image7 from '../../../assets/images/partners/studio/07.webp'
import image8 from '../../../assets/images/partners/studio/08.webp'
import ImageItem from '../../common/ImageItem/ImageItem'
import './Partners.scss'

const Partners = () => {
  const profissionals = [
    {
      name: 'Rick Bonadio',
      position: 'CEO E PRODUTOR',
      image: rickBonadio,
    },
    {
      name: 'Gabriela Bonadio',
      position: 'DIRETORA DE MARKETING',
      image: GabiBonadio,
    },
    {
      name: 'Fernando Prado',
      position: 'DIRETOR ARTÍSTICO E PRODUTOR',
      image: FernandoPrado,
    },
    {
      name: 'Sérgio Fouad',
      position: 'DIRETOR ARTÍSTICO E PRODUTOR',
      image: Fouad,
    },
    {
      name: 'Fernando DeGino',
      position: 'GERENTE E PRODUTOR',
      image: FernandoDeGino,
    },
    // {
    //   name: 'Junior Lanne',
    //   position: 'GERENTE TÉCNICO',
    //   image: Lanne,
    //   social: {
    //     facebook: 'https://www.facebook.com/',
    //     instagram: 'https://www.instagram.com/',
    //   },
    // },
    {
      name: 'Matheus Peixoto',
      position: 'GERENTE DE DIGITAL',
      image: Peixoto,
    },
    // {
    //   name: 'Léo Mergulhão',
    //   position: 'GERENTE DE PRODUTO',
    //   image: Mergulhao,
    // }
    // {
    //   name: 'Rodrigo Castanho',
    //   position: 'PRODUTOR',
    //   image: Rodrigo,
    //   social: {
    //     facebook: 'https://www.facebook.com/',
    //     instagram: 'https://www.instagram.com/',
    //   },
    // },
  ]

  const images = [
    { thumbnail: image1, fullsize: image1 },
    { thumbnail: image2, fullsize: image2 },
    { thumbnail: image3, fullsize: image3 },
    { thumbnail: image4, fullsize: image4 },
    { thumbnail: image5, fullsize: image5 },
    { thumbnail: image6, fullsize: image6 },
    { thumbnail: image7, fullsize: image7 },
    { thumbnail: image8, fullsize: image8 },
  ];


  return (
    <div className='Partners'>
      <div className="background-partners" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container-title">
          <h1>Bem-vindo(a) ao Midas</h1>
          <span>MIDAS MUSIC</span>
        </div>
      </div>
      <div className="container-partners">
        <div className="container-partners-title">
          <div className="title">
            <h1>Profissionais</h1>
            <span>NOSSO TIME</span>
          </div>
          <p>Somos uma equipe experiente e apaixonada por música. Nossa misão é <br /> ajudar e apoiar artistas na tarefa de produzir música de qualidade.</p>
        </div>
        <div className="profissionals-partners">
          {profissionals.map((profissional) => (
            <div className="profissional" key={profissional.name}>
              <img loading="lazy" className='profissional-image' src={profissional.image} alt={profissional.name} />
              <div className="infos-title">
                <span>{profissional.name}</span>
                <p>{profissional.position}</p>
              </div>
              <div className="buttons-partners">
                {profissional?.social?.facebook &&
                  <a href={profissional?.social?.facebook} target="_blank" rel="noopener noreferrer">
                    <span className="ri--facebook-fill" />
                  </a>
                }
                {profissional?.social?.instagram &&
                  <a href={profissional?.social?.instagram} target="_blank" rel="noopener noreferrer">
                    <span className="mdi--instagram" />
                  </a>
                }
                {profissional?.social?.email &&
                  <a href={`mailto:${profissional?.social?.email}`}>
                    <span className="pajamas--mail" />
                  </a>
                }
              </div>
            </div>
          ))}
        </div>
        <div className="gallery-partners">
          {images.map((image, index) => (
            <ImageItem
              key={index}
              thumbnail={image.thumbnail}
              fullsize={image.fullsize}
              alt={`Image ${index}`}
            />
          ))}
        </div>
        <div className="text-partners">
          <p>Alguma dúvida?</p>
          <p>Seja você um artista experiente e consagrado ou um hovem talento em começo de carreira, nossa quipe está pronta para tirar qualquer dúvida sobre os processos de gravação, mixagem, masterização, etc.</p>
          <p>Fale com a gente!</p>
        </div>
      </div>
    </div >
  )
}

export default Partners
