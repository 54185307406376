import React from 'react'
import Slider from 'react-slick'
import bg from '../../../assets/images/communities/bg.webp'
import email from '../../../assets/images/communities/email.svg'
import hours from '../../../assets/images/communities/hours.svg'
import phone from '../../../assets/images/communities/phone.svg'
import image1 from '../../../assets/images/communities/slide/foto1.webp'
import image2 from '../../../assets/images/communities/slide/foto2.webp'
import image3 from '../../../assets/images/communities/slide/foto3.webp'
import image4 from '../../../assets/images/communities/slide/foto4.webp'
import image5 from '../../../assets/images/communities/slide/foto5.webp'
import image6 from '../../../assets/images/communities/slide/foto6.webp'
import './Communities.scss'

import facebook from '../../../assets/images/communities/facebook.svg'
import instagram from '../../../assets/images/communities/instagram.svg'
import tiktok from '../../../assets/images/communities/tiktok.svg'
const Communities = () => {
  const images = [
    { src: image1, alt: "Imagem 1" },
    { src: image2, alt: "Imagem 2" },
    { src: image3, alt: "Imagem 3" },
    { src: image4, alt: "Imagem 4" },
    { src: image5, alt: "Imagem 5" },
    { src: image6, alt: "Imagem 6" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='Communities'>
      <div className="background-communities" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container-title">
          <div className="title">
            <h1>Midas Academy</h1>
            <span>Midas Academy</span>
          </div>
          <p>Fique por dentro das novidades da gravadora e do Midas Academy</p>
        </div>
      </div>

      <div className="container-contact">
        <div className="header-contact">
          {/* <h2>Faça parte da Comunidade do Midas</h2> */}
          <p>Com toda essa experiência na direção de novos talentos, desenvolvendo grandes produções e com excelência sonora, Rick Bonadio reuniu professores-artistas consagrados do mercado fonográfico e das artes para integrar nossa equipe.
            Juntos, criaram um método próprio para que todos os alunos-artistas tenham o conhecimento e preparo técnico totalmente alinhados com a sua carreira artística.
          </p>

          <p>“É a realização de um sonho e também a forma mais eficaz de deixar um legado de anos dedicados à música e a arte” Rick Bonadio.</p>

          <div className="button-external">
            <a href="https://midasacademy.art/" target="_blank" role="button" rel="noreferrer">
              <span>
                <span>CONHEÇA O MIDAS ACADEMY</span>
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="carousel-container">
        <h2>Espaço</h2>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="client-slider item">
              <div className="image-list">
                <div className="content-image">
                  <img loading="lazy" decoding="async" src={image.src} className="main-image" alt={image.alt} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="bottom-left">
        <h2>Contato</h2>
        <div>
          {/* <img src={location} alt="Localização" title="Localização" loading="lazy" /> */}
          <a href={'https://maps.app.goo.gl/dgC7wPnHBoaZ5uLu5'}>R. Leão XIII, 166 - Jd. São Bento  São Paulo, SP - 02526-000</a>
        </div>
        <div>
          <img src={hours} alt="Horário" title="Horário" loading="lazy" />
          <a href={'https://maps.app.goo.gl/dgC7wPnHBoaZ5uLu5'}>Das 09:00 às 20:00</a>
        </div>
        <div>
          <img src={email} alt="Email" title="Email" loading="lazy" />
          <a href={'mailto:contato@midasacademy.com.br'}>contato@midasacademy.com.br</a>
        </div>
        <div>
          <img src={phone} alt="Telefone" title="Telefone" loading="lazy" />
          <a href={'tel:11966035555'}>(11) 96603-5555</a>
        </div>
        <div>
          <a href={'https://www.facebook.com/profile.php?id=61559137654285'} target='_blank' rel="noreferrer"><img src={facebook} alt="facebook" title="facebook" loading="lazy" /></a>
          <a href={'https://www.tiktok.com/@midasacademyofc?_t=8m3EoLN7zGP&_r=1'} target='_blank' rel="noreferrer"><img src={tiktok} alt="tiktok" title="tiktok" loading="lazy" /></a>
          <a href={'https://instagram.com/midasacademyofc'} target='_blank' rel="noreferrer"><img src={instagram} alt="instagram" title="instagram" loading="lazy" /></a>
        </div>
      </div>
    </div>
  )
}

export default Communities
