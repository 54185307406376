import React from 'react'
import bg from '../../../assets/images/artists/bg-section.webp'
import ArtistCard from '../../common/ArtistCard/ArtistCard'
import './Artists.scss'

// ARTISTAS
import anny from '../../../assets/images/artists/anny.webp'
import bruna from '../../../assets/images/artists/bruna.webp'
import dennix from '../../../assets/images/artists/dennix.webp'
import iuri from '../../../assets/images/artists/iuri.webp'
import morgana from '../../../assets/images/artists/morgana.webp'
import rick from '../../../assets/images/artists/rick.webp'
import sergio from '../../../assets/images/artists/sergio.webp'
import suhai from '../../../assets/images/artists/suhai.webp'
import titas from '../../../assets/images/artists/titas.webp'
import zimbra from '../../../assets/images/artists/zimbra.webp'

// DISCOS\
import disco8 from '../../../assets/images/artists/discos/amado.jpeg'
import disco1 from '../../../assets/images/artists/discos/charlie.jpeg'
import disco7 from '../../../assets/images/artists/discos/cpm.jpeg'
import disco11 from '../../../assets/images/artists/discos/ira.jpeg'
import disco6 from '../../../assets/images/artists/discos/kell.jpeg'
import disco5 from '../../../assets/images/artists/discos/mamonas.jpeg'
import disco3 from '../../../assets/images/artists/discos/manu.jpeg'
import microfonado from '../../../assets/images/artists/discos/microfonado.jpg'
import disco9 from '../../../assets/images/artists/discos/nxzero.jpeg'
import disco12 from '../../../assets/images/artists/discos/rouge.jpeg'
import disco10 from '../../../assets/images/artists/discos/titas.jpeg'
import disco4 from '../../../assets/images/artists/discos/vicka.jpeg'
import bolha from '../../../assets/images/artists/discos/vitor-kley-abolha.jpg'
import disco2 from '../../../assets/images/artists/discos/vitor.jpeg'
import zimbraDisco from '../../../assets/images/artists/discos/zimbra.jpg'

import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import Slider from 'react-slick'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

const Artists = () => {
  const artists = [
    {
      name: 'Titãs',
      backgroundImage: titas,
      socialLinks: {
        facebook: 'https://www.facebook.com/titasoficial',
        twitter: 'https://x.com/titasoficial',
        tiktok: 'https://www.tiktok.com/@titasoficial?_t=8nYcZi9CMcA&_r=1',
        instagram: 'https://www.instagram.com/titasoficial/?hl=en',
      },
    },
    {
      name: 'Zimbra',
      backgroundImage: zimbra,
      socialLinks: {
        facebook: 'https://www.facebook.com/bandazimbra',
        twitter: 'https://x.com/zimbraoficial',
        tiktok: 'https://www.tiktok.com/@bandazimbra?_t=8nYcijtL27Z&_r=1',
        instagram: 'https://www.instagram.com/bandazimbra/',
      },
    },
    {
      name: 'Rick Bonadio',
      backgroundImage: rick,
      socialLinks: {
        facebook: 'https://www.facebook.com/rick.bonadio.9',
        instagram: 'https://www.instagram.com/rickbonadio/',
      },
    },
    {
      name: 'Sérgio Britto',
      backgroundImage: sergio,
      socialLinks: {
        facebook: 'https://www.facebook.com/profile.php?id=100044141061130&locale=pt_BR',
        instagram: 'https://www.instagram.com/sergiobritto_oficial/',
        tiktok: 'https://www.tiktok.com/@sergiobritto_oficial?_t=8nYeAhd9OtB&_r=1',
        twitter: 'https://x.com/sergioBritto',
      },
    },
    {
      name: 'Bruna Magalhães',
      backgroundImage: bruna,
      socialLinks: {
        facebook: 'https://www.facebook.com/bruna.magalhaes.3954?locale=pt_BR',
        instagram: 'https://www.instagram.com/brunamagalhaesmusica/',
        tiktok: 'https://www.tiktok.com/@brunamagalhaes.musica?_t=8nYctlzVEKz&_r=1',
      },
    },
    {
      name: 'Anny Koch',
      backgroundImage: anny,
      socialLinks: {
        facebook: 'https://www.facebook.com/profile.php?id=100090226925795&locale=pt_BR',
        instagram: 'https://www.instagram.com/annyykoch/',
        tiktok: 'https://www.tiktok.com/@annykoch?_t=8nYdxci20aT&_r=1',
      },
    },
    {
      name: 'Suhai',
      backgroundImage: suhai,
      socialLinks: {
        facebook: 'https://www.facebook.com/SUHAImusic',
        instagram: 'https://www.instagram.com/suhaimusic/',
        tiktok: 'https://www.tiktok.com/@suhaimusic?_t=8nYdNrqYDKf&_r=1',
      },
    },
    {
      name: 'Morgana Ferrer',
      backgroundImage: morgana,
      socialLinks: {
        facebook: 'https://www.facebook.com/djmorganaferrer',
        instagram: 'https://www.instagram.com/djmorganaferrer/',
      },
    },
    {
      name: 'Dennix',
      backgroundImage: dennix,
      socialLinks: {
        instagram: 'https://www.instagram.com/dennixoficial/',
      },
    },
  ];

  const images = [
    { src: microfonado, alt: "Microfonado" },
    { src: bolha, alt: "Bolha" },
    { src: zimbraDisco, alt: "Zimbra Disco" },
    { src: disco1, alt: "Charlie Brown Jr." },
    { src: disco2, alt: "Vitor Kley" },
    { src: disco3, alt: "Manu Gavassi" },
    { src: disco4, alt: "Vicka" },
    { src: disco5, alt: "Mamonas Assassinas" },
    { src: disco6, alt: "Kell Smith" },
    { src: disco7, alt: "CPM 22" },
    { src: disco8, alt: "Amado Batista" },
    { src: disco9, alt: "NX Zero" },
    { src: disco10, alt: "Titãs" },
    { src: disco11, alt: "Ira!" },
    { src: disco12, alt: "Rouge" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const counters = [
    {
      id: 1,
      to: 2,
      title: "+2 Bilhõe de <br/> Visualizações no <br/> Youtube",
    },
    {
      id: 2,
      to: 2,
      title: "+2 Bilhões de <br/> Streaming nos players <br/> de música",
    },
    {
      id: 3,
      to: 1000,
      suffix: "",
      title: "+1000 artistas <br/> produzidos",
    },
    {
      id: 4,
      to: 12,
      suffix: "",
      title: "Premiações Grammy",
    },
  ];

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });


  return (
    <section className='Artists'>
      <div className="background-artists" style={{ backgroundImage: `url(${bg})` }}>
        <div className="container-title">
          <div className="title">
            <h1>Artistas</h1>
            <span>MIDAS MUSIC</span>
          </div>
          <p>Nossos artistas criam música de qualidade. EPs e singles são distribuídos <br /> em todo o mundo.</p>
        </div>
      </div>
      <div className="container-artists">
        <div className="box-artists">
          {artists.map((artist) => (
            <ArtistCard name={artist.name} backgroundImage={artist.backgroundImage} socialLinks={artist.socialLinks} />
          ))}
        </div>
      </div>

      <div className="container-videos-artists">
        <div className="container-videos-title">
          <div className="title">
            <h1>VIDEOCLIPES</h1>
            <span>DESTAQUES</span>
          </div>
          <p>Confira os lançamentos Midas Music</p>
        </div>
        <div className="box-videos">
          <div className="box">
            <iframe
              src="https://www.youtube.com/embed/5YbRz6uQxW0?si=nvRIOILLGqBUAKZO"
              title="Vitor Kley - O Que É Uma Música?"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen>
            </iframe>
          </div>
          <div className="box">
            <iframe
              src="https://www.youtube.com/embed/LAZv8FGEafM?si=5Y0H6oVMUh2lmBKc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>

      <div className="container-productions">
        <p className='title'>Produções Midas</p>
        <div className="carousel-container">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="client-slider item">
                <div className="image-list">
                  <div className="content-image">
                    <img loading="lazy" decoding="async" src={image.src} className="main-image" alt={image.alt} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="container-counts">
        <div className="counter-container" ref={ref}>
          {counters.map((counter) => (
            <div key={counter.id} className="counter-column">
              <div className="counter-widget">
                <div className="counter-number-wrapper">
                  <span className="counter-prefix"></span>
                  {inView && (
                    <CountUp
                      start={0}
                      end={counter.to}
                      duration={3}
                      separator="."
                      suffix={counter.suffix}
                    />
                  )}
                  <span className="counter-suffix"></span>
                </div>
                <div
                  className="counter-title"
                  dangerouslySetInnerHTML={{ __html: counter.title }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Artists
