import React from 'react'
import './LocationsHome.scss'
// ESTÚDIO 1
import image1 from '../../../assets/images/communities/slide/foto1.webp'
import image2 from '../../../assets/images/communities/slide/foto2.webp'
import image3 from '../../../assets/images/communities/slide/foto3.webp'
import image5 from '../../../assets/images/communities/slide/foto5.webp'
import image6 from '../../../assets/images/communities/slide/foto6.webp'

import Slider from 'react-slick'
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

const LocationsHome = () => {
  const images = [
    { src: image1, alt: "Imagem 1" },
    { src: image2, alt: "Imagem 2" },
    { src: image3, alt: "Imagem 3" },
    { src: image5, alt: "Imagem 5" },
    { src: image6, alt: "Imagem 6" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='LocationsHome'>
      <div className="container-locations-title">
        <div className="title">
          <h1>Midas Academy</h1>
          <span>Midas Academy</span>
        </div>
      </div>

      <div className="carousel-container">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="client-slider item">
              <div className="image-list">
                <div className="content-image">
                  <img loading="lazy" decoding="async" src={image.src} className="main-image" alt={image.alt} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default LocationsHome
