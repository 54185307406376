import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/navbar/logotipo.png';
import './Footer.scss';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('Enviado com sucesso');
  };

  return (
    <div className='Footer'>
      <div className="container-footer">
        <div className="top">
          <div className="two-columns">
            <div className="footer-logo">
              <img className='logo' loading="lazy" decoding='async' src={logo} alt="logo" />
              <ul>
                <li >
                  <a href="https://web.archive.org/web/20240227200347/https://goo.gl/maps/a3NFXDYuVwGbJLx49">
                    <span class="ph--map-pin-fill" />
                    <span className="contact-text">
                      R. Leão XIII, 116 - Jd. São Bento - São Paulo  <br /> - SP - 02526-000
                    </span>
                  </a>
                </li>
                <li >
                  <a href="https://web.archive.org/web/20240227200347/https://goo.gl/maps/a3NFXDYuVwGbJLx49">
                    <span class="ic--baseline-email" />
                    <span className="contact-text">
                      servicos@midasmusic.com.br
                    </span>
                  </a>
                </li>

              </ul>
              <div className="buttons-social">
                <a className="button-social" href="https://www.facebook.com/MidasMusicOficial" target="_blank" rel="noreferrer">
                  <span class="text-hidden-accesible">Facebook-square</span>
                  <span class="brandico--facebook-rect" />
                </a>
                <a className="button-social insta" href="https://www.instagram.com/midasmusic/" target="_blank" rel="noreferrer">
                  <span class="text-hidden-accesible">Instagram-square</span>
                  <span class="mdi--instagram" />
                </a>

                <a className="button-social " href="https://www.youtube.com/@MidasMusic" target="_blank" rel="noreferrer">
                  <span class="text-hidden-accesible">Instagram-square</span>
                  <span class="mdi--youtube" />
                </a>
                <a className="button-social " href="https://www.tiktok.com/@midasmusic" target="_blank" rel="noreferrer">
                  <span class="text-hidden-accesible">Tiktok</span>
                  <span class="mdi--tiktok" />
                </a>
              </div>
            </div>

            <div className="footer-links">
              <span className='title-footer'>Links</span>
              <ul >
                <li>
                  <Link to='/'>
                    <span class="fa--angle-right" />
                    <span class="text-click">Principal</span>
                  </Link>
                </li>
                <li>
                  <Link to='/equipe'>
                    <span class="fa--angle-right" />
                    <span class="text-click">Midas Music</span>
                  </Link>
                </li>
                <li>
                  <Link to='/estudios'>
                    <span class="fa--angle-right" />
                    <span class="text-click">Estúdios</span>
                  </Link>
                </li>
                <li>
                  <Link to='/artistas'>
                    <span class="fa--angle-right" />
                    <span class="text-click">Artistas</span>
                  </Link>
                </li>
                <li>
                  <Link to='/servicos'>
                    <span class="fa--angle-right" />
                    <span class="text-click">Serviços</span>
                  </Link>
                </li>
                <li>
                  <Link to='/comunidade'>
                    <span class="fa--angle-right" />
                    <span class="text-click">Comunidade</span>
                  </Link>
                </li>
                <li>
                  <Link to='/contato'>
                    <span class="fa--angle-right" />
                    <span class="text-click">Contato</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-newsletter">
            <span className='title-footer'>Newsletter</span>
            <p>Assinando a Newsletter do Midas Music, você recebe a cada semana os principais lançamentos da gravadora. Músicas, clipes, shows, workshops e tudo que cerca o universo da música e do Midas.</p>

            <form onSubmit={handleSubmit} className="mailchimp-form">
              {message && <p className="message">{message}</p>}
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="email-input"
                  placeholder="Seu email aqui"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="submit-button">
                  Enviar
                </button>

              </div>
            </form>
          </div>
        </div>

        <div className="bottom">
          <p>Midas Music</p>
          <p>Copyright © 2022. Todos os direitos reservados.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
